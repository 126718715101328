@media (max-width: 576px) {
    .logo{
        height: 100vh;
        background:
        url("../images/cinescan-logo.png")    /* image */
        50% 35% / 326px 156px /* position / size */
        no-repeat                /* repeat */
        transparent 
    }
}

@media (min-width: 577px) {
    .logo{
        height: 100vh;
        background:
        url("../images/cinescan-logo.png")    /* image */
        50% 35% / 444px 214px /* position / size */
        no-repeat                /* repeat */
        transparent 
    }
}

@media (min-width: 768px) {
    .logo{
        height: 100vh;
        background:
        url("../images/cinescan-logo.png")    /* image */
        50% 35% / 522px 250px /* position / size */
        no-repeat                /* repeat */
        transparent 
    }
}

@media (min-width: 992px) {
    .logo{
        height: 100vh;
        background:
        url("../images/cinescan-logo.png")    /* image */
        50% 35% / 522px 250px /* position / size */
        no-repeat                /* repeat */
        transparent
    }
}

@media (min-width: 1200px) {
    .logo{
        height: 100vh;
        background:
        url("../images/cinescan-logo.png")    /* image */
        50% 30% / 653px 313px /* position / size */
        no-repeat                /* repeat */
        transparent
    }
}

.slide-out-up {
    background-position-y: 25%;
    animation: slide-out-up 0.3s forwards;
}

@keyframes slide-out-up {
    from {background-position-y: 25%; }
    to {background-position-y: -100%; }
  }



