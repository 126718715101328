.movie-description-container {
    font-size: 20px;
    border-top: solid white;
    color: white;
}

.movie-info-container {
    color: white;
    font-size: 20px;
}

.movie-poster,
.movie-poster-mobile {
    object-fit: contain;
}

.movie-poster {
    max-width: 17vw;
}

.movie-poster-mobile {
    max-width: 90vw;
}

.toggle-text {
    color:rgb(102, 102, 255);
    cursor: pointer;
    margin-left: 6px;
}