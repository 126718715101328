.base-search {
  position: relative;
  top: 55%;
}

.slide-on-top {
  top: 55%;
  animation: slide-on-top 0.3s forwards;
}

@keyframes slide-on-top {
  from {
    top: 55%;
  }

  to {
    top: 0%;
  }
}

.suggestions {
  border: 1px solid #999;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 200px;
  overflow-y: auto;
  padding-left: 0;

}

.suggestions a:link {
  text-decoration: none; 
  color: rgb(0, 0, 0);
}

.suggestions a:visited {
  color: rgb(139, 68, 253);
}

.suggestions li {
  /* padding: 0.5rem; */
  padding: 0;
  display: flex;
  background: white;
  list-style: 'none'; 
  border: '1px solid black';
}
.suggestion-active,
.suggestions li:hover a,
.suggestions li a {
  margin: 0px; 
  display: block; 
  width: 100%; 
  height: 100%;
}

.suggestion-active,
.suggestions li:hover {
  cursor: pointer;
  font-weight: 700;
  text-decoration: none; 
}

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}