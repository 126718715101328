body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html,
body,
#app {
    background: linear-gradient(0deg, rgba(187,1,242,1) 0%, rgb(90, 28, 170) 100%);
    min-height: 100vh;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

.colored-nav-link {
    color: #d2b06a
}

.colored-nav-link:hover {
    color: #dac58d
}
  
.gradient-logo-link {
    font-weight: bold;
    background: linear-gradient(90deg, rgba(185,137,72,1) 0%, rgba(210,176,106,1) 50%, rgba(220,193,120,1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

