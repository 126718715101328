.pagination-container {
   font-size: 1rem;
   cursor: default;
   display: flex;
   list-style: none;
}

.pagination-item-active .pagination-link {
   color: #fff;
   background-color: rgba(185, 137, 72, 1);
   border-color: #d2b06a;
}

.pagination-link {
   list-style: none;
   font-weight: bold;
   text-decoration: none;
   color: #7c2bff;
   background-color: #212529;
   border: 1px solid #7c2bff;
   border-radius: 50%;
   border-width: 2px;
   font-size: .75rem;
   height: 26px;
   width: 26px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}