.parentDisable{
    position: fixed;
    top: 0;
    left: 0;
    background: #666;
    opacity: 0.3;
    z-index: 998;
    height: 100%;
    width: 100%;
  }
  
  .overlay-box {
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    color: white; background: #666666; opacity: .8;
    z-index: 1000;
  }

  .requested-movies {
    font-size: calc(3px + 2vmin);
    list-style: none;
  }

.requested-movies a:link {
  text-decoration: none; 
  color: rgb(255, 255, 255);
}

/* a:visited,
a:hover,
a:active {
  text-decoration: none; 
  color: rgb(182, 137, 255);
} */

.list-element {
  font:Arial, sans-serif;
  font-style: bold;
  background-color: transparent;
  padding-bottom: 5px;
}

.list-element:not(:last-of-type) {
  border-bottom: 1px ridge #fff;
}

.list-element
.list-element a:link {
  color: rgb(255, 255, 255);
}
.list-element a:visited,
.list-element a:hover,
.list-element a:active {
  color: rgb(182, 137, 255);
}

.desktop-font {
  font-size: 21px;
}

.mobile-font {
  font-size: 17px;
}